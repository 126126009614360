import React, { useEffect, useState } from 'react';
import api from '../../api'
import PropTypes from 'prop-types';
import ContentHeader from '../ContentHeader';
import GroupItem from '../GroupItem';
import styles from './styles.module.scss';
import GroupItemContainer from '../GroupItemContainer'
import UserDetailsPopUp from '../PopUps/UserDetailsPopUp'
const Users = props => {
    const [userData, setUserData] = useState(null)
    const getUserData = async () => {
        try {
            const result = await api.groups.usersFetch()
            console.log(result)

            var results = result.reduce((unique, o) => {
                if (o.firstName.includes('Beta') || o.lastName.includes('Beta')) unique.push(o);
                if(!unique.some(obj => obj.firstName === o.firstName && obj.lastName === o.lastName && obj.phone === o.phone)) {
                  unique.push(o);
                }
                return unique;
            },[]);
            // results = results.filter(function( obj ) {
            //     return obj.quarterPoints === 0;
            // });

            for (let i = results.length - 1; i >= 0; --i) {
                // if (results[i].quarterPoints === 0 && results[i].quarterSteps === 0) {
                //     results.splice(i,1);
                // }

                if (!(results[i].secondaryInfo || {}).area && !(results[i].quarterSteps || 0)) results.splice(i, 1);
            }
            console.log(results.filter(result => result.groupsCount > 2));
            setUserData(results)

        } catch (e) {
            console.log(e)
        }
    }
    useEffect(() => {
        getUserData()
    }, []);



    const [selectedUser, setSelectedUser] = useState(null);
    console.log(userData)
    return (
        <div className={styles.Users}>
            <ContentHeader>Users</ContentHeader>
            <GroupItemContainer
                column1="Name"
                column2="Phone"
                column3="The Quarter (Points)"
            >
                {userData && userData.map(userData => {
                    return (
                        <GroupItem
                        key={userData._id}
                        groupColumn1={userData.firstName + ' ' + userData.lastName}
                        groupColumn2={userData.phone}
                        groupColumn4={userData.quarterPoints}
                        onClick={() => setSelectedUser(userData)}
                        />
                    );
                })}
            </GroupItemContainer>
            {selectedUser ?
                <UserDetailsPopUp
                    group={selectedUser}
                    closeAction={() => {
                        setSelectedUser(null);
                        getUserData();
                    }}
                />
                : null}
        </div>
    );
};

Users.propTypes = {};

export default Users;