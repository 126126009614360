
import { request } from './';

const fetch = async (user, routine) => {
    const response = await request('/debug/fetch', { user, routine });

    for (const data of response.data.reverse()) {
        console.log('data', data);
        // write helpful messages for most common issues
    }

    return 'No issues found';
};

export default { fetch };
