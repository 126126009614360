
import axios from 'axios';
import authHeader from '../utils/authHeader';
import authStorage from '../utils/authStorage';

import auth from './auth';
import groups from './groups';
import badges from './badges'
import analytics from './analytics'
import rewards from './rewards'
import debug from './debug';

//below will be our other api files
export default { auth, groups, badges, analytics, rewards, debug };
// export default {IN HERE};

// MARK: Convenience

const urls = {
    'development': 'https://api.activemarion.app',
    'production': 'https://api.activemarion.app',
    'staging': 'https://api.activemarion.app'
};

export const baseAPI = () => {
    const url = urls[process.env.NODE_ENV];
    return url || urls['development'];
};

export const request = (route, data) => {
    const auth = authStorage.get();
    if (auth && auth.token) return axios.post(baseAPI() + route, data, auth ? authHeader() : null);
      else return axios.post(baseAPI() + route, data); 

};
// export const requestData = (route, data) =>{
//     console.log(data)
    
//     const auth = authStorage.get();

//     if (auth && auth.token) return axios.get(baseAPI() + route, data, authHeader());
//     else return axios.get(baseAPI() + route, data);
// }

export const storage = authStorage;
